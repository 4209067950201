import './styles.css'
import * as React from 'react'

import { Wrapper, Title } from './styled-components'

const HomePage = () => {
    return (
        <Wrapper>
            <Title>rolud</Title>
        </Wrapper>
    )
}

export default HomePage
